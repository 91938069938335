<template>
  <div class="page1">
    <!--面包屑-->
    <div class="breadcrumb"></div>
    <!--主体内容-->
    <div class="content">
      <h2 class="title">
        <div class="title_icon"></div>
        查看计费规则
      </h2>
      <!--搜索条件区域-->
      <div class="searchWrapper">
        <div class="top">
          <el-form
            label-position="right"
            label-width="120px"
            :model="formInline"
            style="width: 300px; margin-left: 50px"
          >
            <el-form-item label="规则名称：" prop="feeName">
              <span>{{ formInline.feeName }}</span>
            </el-form-item>
            <el-form-item label="编码：" prop="feeCode">
              <span>{{ formInline.feeCode }}</span>
            </el-form-item>
            <el-form-item
              label="描述："
              prop="description"
              style="width: 550px; word-break: break-all"
            >
              <pre>{{ formInline.description }}</pre>
            </el-form-item>
          </el-form>
          <el-form
            label-position="right"
            label-width="120px"
            :model="formInline"
            style="width: 300px; margin-left: 50px"
          >
            <el-form-item label="操作人：" prop="operationManagerName">
              <span>{{ formInline.operationManagerName }}</span>
            </el-form-item>
            <el-form-item label="操作时间：" prop="updatedTime">
              <span>{{ formInline.updatedTime }}</span>
            </el-form-item>
          </el-form>
        </div>
        <div class="middle">
          <el-form
            label-position="right"
            label-width="170px"
            class="m_form"
            v-for="(rule, index) in formInline.source.rules"
            :key="index"
          >
            <el-form-item label="生效日期：" label-width="106px" prop="description">
              <span>{{ rule.date }}</span>
            </el-form-item>
            <div style="width: 126px; float: left; line-height: 40px; color: #606266">
              工作日计费规则：
            </div>
            <div style="float: left">
              <el-form-item label="时段：">
                <span>{{ rule.workday.start + "-" + rule.workday.end }}</span>
              </el-form-item>
              <el-form-item>
                <span>{{
                  rule.workday.id == 103 || formInline.source.id == 103
                    ? "基础时段"
                    : rule.workday.id == 100 || formInline.source.id == 100
                    ? "按次计费"
                    : "基础时段"
                }}</span>
              </el-form-item>
              <div v-if="rule.workday.id == 103 || formInline.source.id == 103">
                <el-form-item
                  :label="'区间' + (index + 1) + '：'"
                  prop="description"
                  v-for="(item, index) in rule.workday.items"
                  :key="item.cid"
                >
                  区间分钟数：{{ item.period }}<span class="feeDis">{{ item.unit }}元/30分钟</span>
                </el-form-item>
                <el-form-item label="*时段最高收费金额：" label-width="170px">
                  <span>{{ rule.workday.limit }}元</span>
                </el-form-item>
              </div>
              <div v-if="rule.workday.id == 100 || formInline.source.id == 100">
                <el-form-item
                  :label="'区间' + (index + 1) + '：'"
                  prop="description"
                  v-for="(item, index) in rule.workday.items"
                  :key="item.cid"
                >
                  <span>{{ item.unit }}元/次</span>
                </el-form-item>
              </div>
            </div>
            <div style="clear: both"></div>
            <div style="margin-bottom: 30px"></div>
            <div style="width: 126px; float: left; line-height: 40px; color: #606266">
              非工作日计费规则：
            </div>
            <div style="float: left">
              <el-form-item label="时段：">
                <span>{{ rule.weekend.start + "-" + rule.weekend.end }}</span>
              </el-form-item>
              <el-form-item>
                <span>{{
                  rule.weekend.id == 103 || formInline.source.id == 103
                    ? "基础时段"
                    : rule.weekend.id == 100 || formInline.source.id == 100
                    ? "按次计费"
                    : ""
                }}</span>
              </el-form-item>
              <div v-if="rule.weekend.id == 103 || formInline.source.id == 103">
                <el-form-item
                  :label="'区间' + (index + 1) + '：'"
                  prop="description"
                  v-for="(item, index) in rule.weekend.items"
                  :key="item.cid"
                >
                  区间分钟数：{{ item.period }}<span class="feeDis">{{ item.unit }}元/30分钟</span>
                </el-form-item>
                <el-form-item label="*时段最高收费金额：" label-width="170px">
                  <span>{{ rule.weekend.limit }}元</span>
                </el-form-item>
              </div>
              <div v-if="rule.weekend.id == 100 || formInline.source.id == 100">
                <el-form-item
                  :label="'区间' + (index + 1) + '：'"
                  prop="description"
                  v-for="(item, index) in rule.workday.items"
                  :key="item.cid"
                >
                  <span>{{ item.unit }}元/次</span>
                </el-form-item>
              </div>
            </div>
            <div style="clear: both"></div>
          </el-form>
        </div>
        <!-- <div class="bottom">
          <el-form label-position="right" label-width="150px">
            <el-form-item label="配置路段：">
              <span v-html="feeParkVOList"></span>
            </el-form-item>
          </el-form>
        </div> -->
        <div style="text-align: center">
          <el-button @click="$router.go(-1)">返回</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { dateFormat } from "../../../common/js/public.js";

export default {
  name: "hello",
  data() {
    return {
      feeId: "",
      formInline: {
        feeName: "",
        feeCode: "",
        updatedTime: "",
        createdTime: "1",
        assetModel: "",
        description: "",
        creatorId: "",
        source: {
          rules: [],
        },
      },
      source: [],
      feeParkVOList: "",
    };
  },
  methods: {
    showFormatter(data) {
      return dateFormat(data);
    },
    getFeeData() {
      this.$axios.get("/acb/2.0/fee/byId/" + this.$route.query.feeId).then((res) => {
        if (res.state == 0) {
          this.formInline = res.value;
          this.formInline.source = JSON.parse(res.value.property);
          this.formatterParkList(this.formInline.feeParkVOList);
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    formatterParkList(list) {
      if (!list || list.length == 0) {
        this.feeParkVOList = "无";
      } else {
        let str = "";
        list.forEach((item) => {
          let str2 = "";
          if (item.berthList) {
            item.berthList.forEach((item2) => {
              str2 += "【" + item2.berthCode + "】泊位、";
            });
            str2 = str2.slice(0, -1);
          }
          str += item.parkName + `<span style="font-size: 12px;">${str2}</span>、`;
        });
        this.feeParkVOList = str.slice(0, -1);
      }
    },
  },
  components: {},
  mounted() {
    this.getFeeData();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height: 35px;

.content
  background: #FFFFFF;
  overflow: hidden;
  border: 1px solid #C0CCDA;
  border-radius: 4px;
  padding: 20px;
  .el-form-item
    margin: 0;

.top
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  padding: 10px 0;

.middle
  .m_form
    border: 1px solid #e1e1e1;
    padding: 10px 60px;
    border-radius: 5px;
    margin: 20px 0;

.bottom
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  padding: 10px 0;
  margin: 10px 0;


.feeDis
  margin-left: 20px
</style>
